import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import { Message } from "element-ui";

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [

  {
    path: '/',
    name: 'energyIndex',
    component: () => import('../views/EngeryEvaluation/energyIndex'),
    children: [
     
      {
        path: 'project',
        name: 'project',
        redirect: '/project/projectIdent',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/a2ef4a02acf3976117b1d6d51d7910d6.png",
          active: 1,
          name: '政策文件',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'stepInformation',
            name: 'stepInformation',
            meta: {
              active: 1,
              name: '',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/stepInformation')
          },
          {
            path: 'projectIdent',
            name: 'projectIdent',
            meta: {
              active: 1,
              name: '标识项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/projectLibrary/projectIdent')
          },
          {
            path: 'projectRecord',
            name: 'projectRecord',
            meta: {
              active: 2,
              name: '备案项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/projectLibrary/projectRecord')
          },
        ]
      },
      {
        path: 'formal',
        name: 'formal',
        redirect: '/formal/formalPend',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: 'https://cloud.cdmp.tech/ef167ba7f9629c07f2b05228ace9883c.png',
          active: 2,
          name: '标准管理',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'formalPend',
            name: 'formalPend',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formalPend')
          },
          {
            path: 'ceshi',
            name: 'ceshi',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/ceshi')
          },
          {
            path: 'proDetailForZhongxin',
            name: 'proDetailForZhongxin',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/proDetailForZhongxin')
          },
          {
            path: 'proDetailDownLoad',
            name: 'proDetailDownLoad',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/proDetailDownLoad')
          },
          {
            path: 'formDetail',
            name: 'formDetail',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formDetail')
          },
          {
            path: 'totalTable',
            name: 'totalTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/totalTable')
          },
          {
            path: 'ipendTable',
            name: 'ipendTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/ipendTable')
          },
          {
            path: 'reportTable',
            name: 'reportTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/reportTable')
          },
          {
            path: 'formalDistri',
            name: 'formalDistri',
            meta: {
              active: 1,
              name: '审查分配',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formalDistri')
          },

        ]
      },

      {
        path: 'technology',
        name: 'technology',
        redirect: '/technology/technicalIndex',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/51250de322d1de97976c127552af04af.png",
          active: 3,
          name: '初稿审查',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'Draftreview',
            name: 'Draftreview',
            meta: {
              active: 3,
              // name: '初稿审查',
              role: [1]
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/chugaoForzhongxinDetail')
          },
          {
            path: 'technicalIndex',
            name: 'technicalIndex',
            meta: {
              active: 1,
              // name: '初稿审查',
              parent_active: 3,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/technicalIndex')
          },
          // {
          //   path: 'teexportTable',
          //   name: 'teexportTable',
          //   meta: {
          //     active: 2,
          //     name: '',
          //     parent_active: 2,
          //     hidden: true,
          //   },
          //   component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/teexportTable')
          // },
        ]
      },

      {
        path: 'announcement',
        name: 'announcement',
        redirect: '/announcement/publicIndex',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: 'https://cloud.cdmp.tech/41de745af48e09d5fbc7656d4abfb107.png',
          active: 4,
          name: '征求意见稿',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'publicIndex',
            name: 'publicIndex',
            meta: {
              active: 1,
              parent_active: 4,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/publicAnnounced/publicIndex')
          },
          {
            path: 'solicitingOpinions',
            name: 'solicitingOpinions',
            meta: {
              active: 1,
              parent_active: 4,
              // name: '初稿审查',
              role: [1]
            },
            component: () => import('../views/EngeryEvaluation/centerMange/publicAnnounced/zqyjgcheck')
          },
        ]
      },
      {
        path: 'mark',
        name: 'mark',
        redirect: '/mark/identMake',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/8beb2d509eb9b5ef43450982cea55b0c.png",
          active: 5,
          name: '送审稿审查',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'draftReview',
            name: 'draftReview',
            meta: {
              active: 1,
              name: '送审稿审查',
              parent_active: 5,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/identIssue/scgcheck')
          },
          {
            path: 'identMake',
            name: 'identMake',
            meta: {
              active: 1,
              name: '标识制作',
              parent_active: 5,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/identIssue/identMake')
          },
          {
            path: 'identSend',
            name: 'identSend',
            meta: {
              active: 2,
              name: '标识寄取',
              parent_active: 5,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/identIssue/identSend')
          },
        ]
      },
      {
        path: 'statistics',
        name: 'statistics',
        redirect: '/statistics/areaCount',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/4951c7a8479a95447b4c3e93b3786e99.png",
          active: 6,
          name: '报批',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'baopishenhe',
            name: 'baopishenhe',
            meta: {
              active: 1,
              name: '报批审核',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/baopishenhe')
          },
          {
            path: 'areaCount',
            name: 'areaCount',
            meta: {
              active: 1,
              name: '地区统计',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/areaCount')
          },
          {
            path: 'yearsCount',
            name: 'yearsCount',
            meta: {
              active: 2,
              name: '年度统计',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/yearsCount')
          },
          {
            path: 'identCount',
            name: 'identCount',
            meta: {
              active: 4,
              name: '标识等级统计',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/identCount')
          },
          {
            path: 'dataCount',
            name: 'dataCount',
            meta: {
              active: 3,
              name: '数据分析',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/dataCount')
          },

        ]
      },
      {
        path: 'fairPlay',
        name: 'fairPlay',
        redirect: '/fairPlay/fairCompetition',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/4951c7a8479a95447b4c3e93b3786e99.png",
          active: 20,
          name: '公平竞争审查',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'competiteshenhe',
            name: 'competiteshenhe',
            meta: {
              active: 1,
              name: '公平竞争审查审核',
              parent_active: 20,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/competiteshenhe')
          },
          {
            path: 'fairCompetition',
            name: 'fairCompetition',
            meta: {
              active: 1,
              name: '地区统计',
              parent_active: 20,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/fairCompetition')
          },
          {
            path: 'yearsCount',
            name: 'yearsCount',
            meta: {
              active: 2,
              name: '年度统计',
              parent_active: 20,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/yearsCount')
          },
          {
            path: 'identCount',
            name: 'identCount',
            meta: {
              active: 4,
              name: '标识等级统计',
              parent_active: 20,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/identCount')
          },
          {
            path: 'dataCount',
            name: 'dataCount',
            meta: {
              active: 3,
              name: '数据分析',
              parent_active: 20,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/dataCount')
          },

        ]
      },
      {
        path: 'standard',
        name: 'standard',
        redirect: '/standard/standardRelease',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/442a73d0028d98da75f9ab9709d59330.png",
          active: 7,
          name: '公示公告',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [{
          path: 'standardRelease',
          name: 'standardRelease',
          meta: {
            active: 7,
            name: '公示公告列表',
            parent_active: 7,
          },
          component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/standardRelease')
        },
        ]
      },
      {
        path: 'filings',
        name: 'filings',
        redirect: '/filings/filingsDocument',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/3443205a8c374a25d0850ceaeecf7681.png",
          active: 12,
          name: '备案',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [{
          path: 'filingsDocument',
          name: 'filingsDocument',
          meta: {
            active: 12,
            name: '备案列表',
            parent_active: 12,
          },
          component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/filingsDocument')
        },
        ]
      },
      {
        path: 'reexamine',
        name: 'reexamine',
        redirect: '/reexamine/reviewLocation',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/397df63393404a9c3313a0c961ca605c.png",
          active: 13,
          name: '复审',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [{
          path: 'reviewLocation',
          name: 'reviewLocation',
          meta: {
            active: 13,
            name: '复审列表',
            parent_active: 13,
          },
          component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/reviewLocation')
        },
        ]
      },
      {
        path: 'docus',
        name: 'docus',
        redirect: '/docus/instrument',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/e1e8139f7220459b31f220e9b84c7f11.png",
          active: 8,
          name: '标准文件',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [{
          path: 'instrument',
          name: 'instrument',
          meta: {
            active: 8,
            name: '标准文件列表',
            parent_active: 8,
          },
          component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/components/standardFile')
        },
        ]
      },
      {
        path: 'user',
        name: 'user',
        redirect: '/user/accontimlist',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/cdb3fc7116b50f569fa2071c1f7a0f7e.png",
          active: 9,
          name: '系统设置',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'enchildmanage',
            name: 'enchildmanage',
            meta: {
              active: 1,
              name: '个人资料',
              parent_active: 9,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enchildmanage')
          },
          {
            path: 'accontimlist',
            name: 'accontimlist',
            meta: {
              active: 1,
              name: '账号信息列表',
              parent_active: 9,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/accontimlist')
          },
          {
            path: 'accontimInfo',
            name: 'accontimInfo',
            meta: {
              active: 1,
              name: '账号信息',
              parent_active: 9,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/accontimInfo')
          },
          {
            path: 'qiyeuserList',
            name: 'qiyeuserList',
            meta: {
              active: 2,
              name: '企业用户',
              parent_active: 9,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/qiyeuserList')
          },
          {
            path: 'qiyeuser',
            name: 'qiyeuser',
            meta: {
              active: 2,
              name: '企业用户',
              parent_active: 9,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/qiyeuser')
          },
          {
            path: 'exportuser',
            name: 'exportuser',
            meta: {
              active: 3,
              name: '专家用户',
              parent_active: 9,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/exportuser')
          },
        ]
      },

      // 企业
      {
        path: 'projectforcom',
        name: 'projectforcom',
        redirect: '/projectforcom/allProjectForCom',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/a2ef4a02acf3976117b1d6d51d7910d6.png",
          active: 1,
          name: '政策文件',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'allProjectForCom',
            name: 'allProjectForCom',
            meta: {
              active: 1,
              name: '标识项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/allProjectForCom')
          },
          {
            path: 'itemRecord',
            name: 'itemRecord',
            meta: {
              active: 2,
              name: '备案项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/itemRecord')
          },
          {
            path: 'itemRecordDetail',
            name: 'itemRecordDetail',
            meta: {
              active: 2,
              name: '备案项目',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/itemRecordDetail')
          },
          {
            path: 'ipImformation',
            name: 'ipImformation',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipImformation')
          },
          {
            path: 'updateProject',
            name: 'updateProject',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/updateProject')
          }
        ]

      },
      {
        path: 'user_project',
        name: 'user_project',
        redirect: '/user_project/itemIdent',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: 'https://cloud.cdmp.tech/ef167ba7f9629c07f2b05228ace9883c.png',
          active: 2,
          name: '标准管理',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'chugaoForqiyeDetail',
            name: 'chugaoForqiyeDetail',
            meta: {
              active: 2,
              name: '项目申报',
              parent_active: 3,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/chugaoForqiyeDetail')
          },
          {
            path: 'itemIdent',
            name: 'itemIdent',
            meta: {
              active: 1,
              name: '标准管理',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/itemIdent')
          },

          {
            path: 'addImformation',
            name: 'addImformation',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/addImformation')
          }
        ]

      },      
      {
        path: 'user_user',
        name: 'user_user',
        redirect: '/user_user/enchildmanage',
        meta: {
          icon: 'https://cloud.cdmp.tech/8cd512179175da43c36352b580353c71.png',
          icon2: "https://cloud.cdmp.tech/cdb3fc7116b50f569fa2071c1f7a0f7e.png",
          active: 7,
          name: '用户管理',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'enchildmanage',
            name: 'enchildmanage',
            meta: {
              active: 1,
              name: '企业信息',
              parent_active: 7,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enchildmanage')
          },
          {
            path: 'enInformation',
            name: 'enInformation',
            meta: {
              active: 2,
              name: '子账户管理',
              parent_active: 7,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enInformation')
          },
        ]
      },
      // 专家
      {
        path: 'export',
        name: 'export',
        redirect: '/export/exportIpend',
        meta: {
          icon: 'http://cloud.cdmp.tech/c5bfedf80cea4247cd392f03c236d8ec.png',
          icon2: 'http://cloud.cdmp.tech/e1fdd463f62310a18356f49a747a4e30.png',
          active: 1,
          name: '专家审查',
          role: [2]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'exportIpend',
            name: 'exportIpend',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportIpend')
          },
          {
            path: 'teexportTable',
            name: 'teexportTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/teexportTable')
          },
          {
            path: 'exreportTable',
            name: 'exreportTable',
            meta: {
              active: 1,
              name: '',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exreportTable')
          },
          {
            path: 'exportProce',
            name: 'exportProce',
            meta: {
              active: 2,
              name: '已处理',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportProce')
          },
        ]
      },
      {
        path: 'export_user',
        name: 'export_user',
        redirect: '/export_user/exportmange',
        meta: {
          icon: 'http://cloud.cdmp.tech/b2227a09578e6b3a5818fbb06aed9322.png',
          icon2: 'https://cloud.cdmp.tech/a40be1c0f01381ef735cace670be161e.png',
          active: 2,
          name: '账号管理',
          role: [2]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'exportmange',
            name: 'exportmange',
            meta: {
              active: 1,
              name: '',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportmange')
          },
        ]

      },

    ]
  },
  {
    path: "/login",
    name: '登录',
    component: () => import('../views/Login')
  },
  {
    path: "/register",
    name: '注册',
    component: () => import('../views/Register')
  },
  {
    path: "/password",
    name: '修改密码',
    component: () => import('../views/password')
  }
]

const router = new VueRouter({
  routes
})

//增加路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == '/login' || to.path == '/register' || to.path == '/password') {
    next();
  } else {
    if (!store.state.token || store.state.token == '') {
      let user = localStorage.getItem('energy_user');
      if (user && user != '') {
        store.commit('set_user', JSON.parse(user));
      }
    }
    if (store.state.token && store.state.token != '') {
      if (to.name == 'energyIndex') {
        if (store.state.role.indexOf(1) != -1) {
          next({ path: '/project/projectIdent' })
        } else if (store.state.role.indexOf(3) != -1) {
          next('/projectforcom/allProjectForCom')
        } else {
          next('/export/exportIpend')
        }
      } else {
        next();
      }
    } else {
      Message.warning('请先登录');
      next({ path: '/login' })
    }

  }
})

export default router
