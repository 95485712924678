import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    // role: ['admin','enterprise','expert']
    role: ['enterprise'],  //1管理员 2专家 3企业
    user: '',
    messageList: []
  },
  mutations: {
    set_message(state, message) {
      state.messageList = message
    },
    set_user(state, user) {
      let role = [];
      // if (!user.type){
      //   role  = [1];
      // }else {
      //   role.push(user.type);
      // }
      role.push(user.type);
      localStorage.setItem('energy_token', user.token)
      localStorage.setItem('energy_role', role)
      localStorage.setItem('energy_user', JSON.stringify(user))
      state.user = user;
      state.token = user.token;
      state.role = role;
    },
    set_token(state, token) {
      state.token = token
    },
    set_role(state, role) {
      state.role = role
    },
    login_out(state) {
      localStorage.removeItem('energy_user');
      localStorage.removeItem('energy_token');
      localStorage.removeItem('energy_role');
      state.user = '';
      state.token = '';
      state.role = '';
      router.push({ path: '/login' })
    }
  },
  actions: {
  },
  modules: {
  }
})
